import React from 'react'
import { graphql } from 'gatsby'
import Errors from 'gatsby-theme-tailwindui/src/components/graphql-error-lists'
import Page from 'gatsby-theme-tailwindui/src/templates/page'

export const query = graphql`
  fragment SanityImage on SanityFeaturedImage {
    alt
    crop {
      _key
      _type
      top
      bottom
      left
      right
    }
    hotspot {
      _key
      _type
      x
      y
      height
      width
    }
    asset {
      _id
      metadata {
        lqip
        dimensions {
          aspectRatio
          width
          height
        }
      }
    }
  }

  query InsightpageQuery {
    page: sanityPage(
      _id: { regex: "/(drafts.|)a4bee936-c9d7-4a9c-9d44-60d3c6adc16e/" }
    ) {
      ...PageInfo
    }

    emails: allSanityEmails {
      edges {
        node {
          id
          title
          url
          description
          _id
        }
      }
    }

    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
      openGraph {
        title
        description
        image {
          ...SanityImage
        }
      }
    }
  }
`

const IndexPage = (props) => {
  const { data, errors } = props

  if (errors) {
    return <Errors errors={errors} />
  }

  return <Page data={data} />
}

export default IndexPage
